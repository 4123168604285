@import "variables.scss";

.profile-dropdown {
  .globe-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #333; // Adjust the color as needed
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  .custom-dropdown {
    min-width: 250px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    font-size: 0.9rem;

    .user-info {
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid #f0f0f0;

      strong {
        font-size: 1rem;
      }

      .username {
        font-size: 0.85rem;
        color: #888;
      }
    }

    .dropdown-item-custom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1rem;
      font-size: 0.9rem;
      transition: background-color 0.2s;

      .menu-icon {
        margin-right: 0.5rem;
        font-size: 1rem;
        color: #555;
      }

      .shortcut {
        font-size: 0.8rem;
        color: #888;
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &.logout {
        color: #d9534f;

        &:hover {
          background-color: #fcecec;
        }
      }
    }
  }
}

.top-strip {
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;

  @media screen and (max-width: 767px) {
    display: block;
  }
  .dropdown-toggle::after {
    border-top: 0;
  }
  .top-strip-marquee {
    color: #ffffff !important;
    // font-family: "Manrope", sans-serif;
    font-family: "Montserrat", sans-serif;
    padding-right: 2rem;
    align-items: center;
    font-size: 1rem;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .nav {
    align-items: center;

    @media screen and (max-width: 767px) {
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .nav-link {
    color: $light-color;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    transition: color 0.3s ease;
    // font-family: "Manrope", sans-serif;
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    padding-left: 0px;

    &::before {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      background: white;
      font-weight: 500;
      transition: width 0.3s ease, background-color 0.3s ease;
      left: 0;
      bottom: -1px !important;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      display: block;
      background: white;
      font-weight: 500;
      transition: width 0.3s ease, background-color 0.3s ease;
      right: 0;
      // bottom: -1px!important;
      margin-top: -3px;
      margin-bottom: 2px;
    }

    &:hover {
      color: #eb1c26 !important;
    }

    &:hover::after {
      width: 0;
      background-color: #eb1c26;
    }
  }
}

.logo {
  height: 50px;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.dropdown-menu {
  &.show {
    margin-top: 0 !important;
  }
}
// .dropdown-toggle::after{
//   display: none !important;
// }
.profile-dropdown-menu {
  min-width: 250px !important;
  padding: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border: none !important;

  a {
    color: #212529 !important;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .dropdown-item {
    font-size: 0.9rem;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -75%;
  width: 75%;
  height: 100%;
  background-color: #fff;
  z-index: 1050;
  transition: left 0.3s ease;
  overflow-y: auto;

  &.open {
    left: 0;
  }

  .dropdown-item {
    position: relative;

    &.active::after {
      content: "";
      position: relative;
      display: block;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
  }

  .nav-item {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 12px;
      width: 0;
      height: 2px;
      /* Underline thickness */
      background-color: #0c3d78;
      /* Underline color */
      transition: width 0.3s ease;
      /* Smooth underline transition */
      margin-left: 8px;
    }

    .nav-link {
      &.active::after {
        content: "";
        position: relative;
        display: block;
        left: 0;
        right: 0;
        bottom: 2px;
        width: 100%;
        /* Keep underline full width on active link */
        height: 2px;
        background-color: #0c3d78;
        // margin-left: 8px;
      }
    }

    &:hover::after {
      width: 80%;
      /* Show underline on hover */
    }
  }

  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;

    .logo {
      height: 40px;
    }

    .close-icon {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .dropdown-menu {
    min-width: 250px;
    padding: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu.show {
    display: block;
    position: relative;
    left: 0;
    box-shadow: none;
    padding: 0;
  }

  .dropdown-item {
    padding: 10px 20px;
    color: #000;

    &:hover {
      color: #007bff;
      background-color: transparent;
    }
  }

  .nav-link {
    color: #000;
    padding: 10px 20px;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 1px solid #ececec;

    &:hover {
      color: #0c3d78;
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  transition: display 0.3s ease;

  &.active {
    display: block;
  }
}



.navbar {
  .logo {
    height: 50px;
    /* Adjust the logo height */
  }
}

.profile-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  /* Choose a color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 8px;
}

.profile-name {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  color: #333;
  margin-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
}

.profile-dropdown-menu {
  min-width: 250px;
  display: block;
  position: relative;
  left: 0;
  padding: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 10px 20px;
  color: #000;

  &:hover {
    color: #007bff;
    background-color: transparent;
  }
}

.profile-dropdown {
  .nav-link::after {
    display: none;
  }

  &:hover {
    &::after {
      display: none;
    }
  }

  .profile-dropdown-toggle {
    &:hover {
      &::after {
        display: none;
      }
    }

    .nav-link {
      &::after {
        display: none;
      }
    }
  }
}

.custom-navbar {

  li.nav-item.active-dropdown.dropdown.nav-item {
    position: relative;
    color: #0c3d78 !important;

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 12px;
      width: 80%;
      height: 2px;
      /* Underline thickness */
      background-color: #0c3d78;
      /* Underline color */
      transition: width 0.3s ease;
      /* Smooth underline transition */
      margin-left: 8px;
    }
  }

  .dropdown-item {
    position: relative;

    &.active::after {
      content: "";
      position: relative;
      display: block;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
  }

  .active-dropdown::after {
    content: "";
    position: relative;
    display: block;
    left: 0;
    right: 0;
    bottom: 2px;
    width: 100%;
    /* Keep underline full width on active link */
    height: 2px;
    background-color: #0c3d78;
    // margin-left: 8px;
  }

  .nav-item {
    text-decoration: none;
    color: #0c3d78;
    /* Text color */
    position: relative;
    /* For positioning the underline */
    padding-bottom: 2px;
    /* Gap between text and underline */

    display: flex;
    align-items: center;
    /* Align icon with text properly */

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 12px;
      width: 0;
      height: 2px;
      /* Underline thickness */
      background-color: #0c3d78;
      /* Underline color */
      transition: width 0.3s ease;
      /* Smooth underline transition */
      margin-left: 8px;
    }

    .nav-link {
      &.active::after {
        content: "";
        position: relative;
        display: block;
        left: 0;
        right: 0;
        bottom: 2px;
        width: 100%;
        /* Keep underline full width on active link */
        height: 2px;
        background-color: #0c3d78;
        // margin-left: 8px;
      }
    }

    &:hover::after {
      width: 80%;
      /* Show underline on hover */
    }
  }
}


.nav-link-header.active {
  position: relative;
  color: #000 !important;
  padding-bottom: 2px;

  /* Underline for active link */
  &::after {
    content: "";
    position: relative;
    display: block;
    left: 0;
    right: 0;
    bottom: 2px;
    width: 100%;
    /* Keep underline full width on active link */
    height: 2px;
    background-color: #0c3d78;
    // margin-left: 8px;
  }
}

/* Dropdown Icon Styling */
.nav-item .dropdown-icon {
  margin-left: 5px;
  /* Gap between text and icon */
  font-size: 12px;
  /* Adjust icon size */
  transition: transform 0.3s ease;
  /* Smooth icon rotation */
}

/* Rotate dropdown icon when open */
.nav-item.dropdown-open .dropdown-icon {
  transform: rotate(180deg);
  /* Rotate icon */
}

#hamburger-menu {
  width: 50px;
  height: 40px;
  margin-left: 1rem;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #0c3d78 !important;
}

.marquee-content {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

.play-pause-icon {
  color: white;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.paused .marquee-content {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(270%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .top-strip-marquee {
    .marquee-content {
      white-space: nowrap;
    }
  }
}