// NotFound.scss
.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  
    .not-found-content {
      max-width: 600px;
      padding: 2rem;
      background: white;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
      h1 {
        font-size: 4rem;
        font-weight: bold;
        color: #343a40;
      }
  
      p {
        font-size: 1.25rem;
        color: #6c757d;
      }
  
      .fa-icon {
        font-size: 8rem;
        color: #ff4757;
        margin-bottom: 1rem;
      }
  
      a {
        // color: #007bff;
        text-decoration: none;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  