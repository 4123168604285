.footer-container {
  position: relative;
  background-color: #002147; // Dark background color
  padding: 50px 0;
  color: white;
  // background-image: url('http://localhost:3001/static/media/footer-bg.a3c48efa3755ca8a6f56.png');
  background-image: url("../img/footer-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }

  p {
    font-size: 1rem;
    font-family: "Monrope", sans-serif;
    font-weight: 500;
    color: white;

    a {
      color: #fff;
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #002147; // Dark blue overlay with 80% opacity
    z-index: 1;
    opacity: 93%;
  }

  .footer-overlay {
    position: relative;
    z-index: 2;
    padding-top: 3rem;
    padding-bottom: 2rem;

    @media screen and (max-width: 767px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .footer-button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 0.825rem 1.2rem;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.8s, background-position 0.8s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, transparent 50%, #002244 50%);

    @media screen and (max-width: 767px) {
      font-size: 0.8rem;
      padding: 0.5rem 0.9rem;
    }

    &:hover {
      font-family: "Montserrat", sans-serif;
      background-color: #1d7abf;
      background-position: right center;
      color: white;
    }
    a{
      text-decoration: none;
      color: white;
    }
  }

  .contact-link {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-logo {
    width: 150px;
    margin-top: 10px;
  }

  h5 {
    font-weight: bold;
    position: relative;
    margin-bottom: 15px;
    display: block;
    font-size: 1.8rem;
    text-decoration: underline;

    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }

    &::after {
      content: "";
      // width: 50%;
      // height: 1px;
      // display: block;
      // background: white;
      // font-weight: 500;
      // transition: width 0.3s ease, background-color 0.3s ease;
      // right: 0;
      // bottom: -1px!important;
    }

    &:hover {
      color: #1d7abf !important;
    }

    &:hover::after {
      width: 0;
      background-color: #1d7abf;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-wrap: nowrap;

    li {
      margin-bottom: 10px;
      font-size: 1rem;

      @media screen and (max-width: 767px) {
        font-size: 1rem;
        white-space: normal;
      }

      a {
        color: white;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  .newsletter-container {
    p {
      font-size: 1rem !important;
      margin-bottom: 10px;
    }

    .newsletter-input {
      padding: 12px;
    }

    .subscribe-button {
      background-color: #001f3f;
      border-color: #001f3f;
      color: white;
      position: absolute;
      right: 8px;
      top: 5px;

      &:hover {
        background-color: #001f3f;
        border-color: #001f3f;
      }
    }
  }

  .footer-divider {
    border-top: 1px solid white;
    margin: 30px 0;
  }

  .footer-bottom {
    font-size: 1rem;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    position: relative;

    .whatsapp-icon {
      position: fixed;
      bottom: 20px;
      right: 20px;
      background-color: #25d366;
      color: #fff;
      font-size: 24px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
      }
    }

    p {
      margin-bottom: 0;
      font-size: 1rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      color: white;
    }
  }

  .footer-bottom .newsletter-container {
    justify-content: flex-end;
    align-items: left;
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }

    .newsletter-input {
      margin-bottom: 30px;
    }

    form {
      display: flex;
      position: relative;
    }
  }

  .social-icons {
    list-style: none;
    justify-content: end;
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    margin-right: -20px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;

      border: 2px solid rgb(204, 201, 201);
      transition: background-color 0.3s, color 0.3s;

      Link {
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #001f3f;
        }
      }

      &:hover {
        background-color: #00aaff;
        border-color: #00aaff;
      }
    }
  }
}