// src/assets/styles/global.scss
@import "variables";

body {
  // font-family: $font-family;
  margin: 0;
  padding: 0;
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.under-main {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  text-align: center;
}

.banner {
  position: relative;
  background: url("../img/contact-banner.jpg") no-repeat center center;
  background-size: cover;
  color: white;
  // padding: 150px 0;
  padding: 130px 0;
  text-align: center;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  .overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 34 68 / 50%);
    z-index: 1;
  }

  h1,
  .breadcrumb {
    position: absolute;
    // z-index: 2;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .breadcrumb {
    font-size: 1rem;

    a {
      color: #ffffff;
      text-decoration: none;
      margin-right: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: #ffffff;
    }
  }
}

.my-btn {
  background: linear-gradient(to right, #52a0fd 0%, #00e2fa 51%, #52a0fd 100%);
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 50px;
  border-radius: 30px;
  transition: 0.5s;
  box-shadow: 0 4px 15px rgba(78, 84, 200, 0.4);
  transition: all 0.3s ease;

  &:hover {
    // transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(78, 84, 200, 0.6);
    background-position: right center;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(78, 84, 200, 0.4);
  }
}

.card {
  position: relative;
  // padding: 30px;
  // border-radius: 15px;
  // background: #fff;
  // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shape {
  border-radius: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  // opacity: 5%;
  // z-index: -1;
  // display: block;
  border-radius: 15px;
}

.contact-us {
  position: relative;
  padding: 20px;
  // background-color: #fff;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1; // Ensure the card is above the background image

  h4,
  h2 {
    margin-bottom: 20px;
  }

  .shape-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; // Ensure the container covers the full width
    height: 100%; // Ensure the container covers the full height
    z-index: -1; // Ensure the image has a negative z-index
    pointer-events: none; // Ensure the image doesn't interfere with other elements

    .img-shape {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 200px; // Adjust the size as needed
      height: auto;
      z-index: -1; // Ensure the image has a negative z-index
    }
  }
}

.img-shape {
  width: 250px;
  height: auto;
}

body {
  font-family: "Montserrat", sans-serif;
  // font-family: 'Open Sans', sans-serif;
  // letter-spacing: 1px;
}

.newsletter-container-contact {
  // background-color: #7384957a;
  background-image: linear-gradient(176deg, #e6e6e6, #c7d8e8);
  min-height: 100vh;
  padding-left: 7rem !important;
  padding-right: 7rem !important;
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;

  @media (max-width: 767px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

.newsletter-info {
  padding-right: 2rem;
  // padding-left: 31px !important;
  padding-right: 30px !important;

  @media (max-width: 767px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.newsletter-heading {
  // color: #373d77;
  color: #062f5e;
  font-size: 1.2rem;
  // padding-left: 30px;
  padding-top: 50px;

  @media (max-width: 767px) {
    padding-top: 1rem;
    font-size: 1rem;
  }
}

.newsletter-title {
  // color: #373d77;
  color: #062f5e;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  // padding-left: 30px;
  // padding-right: 30px;
  line-height: 1;
  margin: 14px 0 0;
  font-family: sans-serif;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.newsletter-text {
  // color: #373d77;
  color: #062f5e;
  font-size: 1.25rem;
  line-height: 1.8;
  margin: 28px 0 0;

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}

.flipbook-page {
  transition: transform 0.6s ease-in-out;
  transform-origin: right center;
}

.flip-animation {
  transform: rotateY(0deg);
}

.ml-5 {
  margin-left: 2rem;
}

.loading-container {
  text-align: center;
  margin-top: 20px;

  .progress-bar {
    width: 80%;
    margin: 0 auto;
    background-color: #e0e0e0;
    border-radius: 20px;
    overflow: hidden;
    height: 20px;
    position: relative;
  }

  .progress {
    background-color: #007bff;
    height: 100%;
    line-height: 20px;
    color: white;
    text-align: center;
    white-space: nowrap;
    transition: width 0.3s ease;
  }

}

.email-link {
  font-family: "Poppins", sans-serif;
  // color: #373d77;
  color: #062f5e;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-decoration: underline;
  text-decoration-thickness: 2px !important;
  /* Thickness of underline */
  text-underline-offset: 3px;

  /* Distance between text and underline */
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}

.follow-heading {
  // color: #373d77;
  color: #062f5e;
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.social-icons {
  display: flex;
  margin-top: 1rem;
}

.social-icon {
  // color: #373d77;
  color: #062f5e;
  margin-right: 1rem;
  font-size: 1.5rem;

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}

.privacy-text {
  color: blue;
  margin-top: 2rem;
}

.contact-form-section {
  background-color: #062f5e;
  border-radius: 15px;
  padding: 15px 30px;

  .form-check-label {
    color: white;
  }

  .form-label {
    color: white;
  }

  @media screen and (max-width: 767px) {
    margin-top: 2rem;
    padding: 1rem 1rem;

    .form-label {
      margin-top: 1rem;
    }

  }
}

.color-white {
  color: white;
}

.font-small {
  font-size: 0.8rem;
}

#map_inner {
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    // width: 100%;
    // height: 100%;
    max-width: 600px;
    max-height: 600px;
  }
}

.member-info {
  .badge {
    margin-left: 0.5rem;
  }
}

.bottom-btns-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
}

.back-btn {
  background: transparent !important;
  background-color: transparent !important;
  text-align: left;
  margin-top: 1rem;
  color: #1d7abf !important;
  border: 0;
}

.contact-heading {
  color: white;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 1rem;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
}

// .form-label {
//   color: white;
// }

.message-input {
  height: 150px;
}

.cptcha-group.mb-3 div {
  max-width: 100% !important;
}

.cptcha-group.mb-3 iframe {
  max-width: 100% !important;
}

.submit-button {
  // background-color: #F8D7DA;
  // color: #6A1B9A;
  // width: 100%;
  // margin-top: 1rem;
  // border: none;
  background-image: linear-gradient(to bottom, #1c7ce0, #150051);
  margin: 10px;
  padding: 8px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.submit-button:hover {
  background-color: #ffb5c5;
}

.navbar-nav {
  font-size: larger !important;
  font-weight: bold !important;
}

textarea.form-control {
  min-height: calc(6.5em + 0.75rem + calc(var(--bs-border-width) * 2)) !important;
}

.form-check-label {
  // color: white;
  margin-bottom: 20px;
}

.p4 {
  padding-top: 30px 50px !important;
}

.color-danger {
  color: red;
  font-size: 0.75rem;
}

.breadcrumb {
  background-color: rgb(0 0 0 / 24%);
  // text-decoration: underline;
  display: flex;
  font-size: 1rem;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 0.2rem 1rem;
  align-items: center;
  width: fit-content;
  color: black;
}

.text-right {
  text-align: right;
}

.banner .breadcrumb,
.banner h1 {
  position: relative;
  z-index: 2;
}

.newsletter-title-footer {
  color: white;
}

.scard {
  font-family: "Open Sans", sans-serif;
  margin-top: 20px !important;
  // padding: 20px;
}

.metrics-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.metric-card {
  background-color: #ffffff;
  border-top: 12px solid #214f81;
  border-radius: 0 0 20px 20px;
  padding: 20px;
  text-align: center;
  width: calc(44%);
  box-shadow: 2px 3px 6px #0d70e38c;
  // border-bottom: #062f5e  1px solid;
  // border-left: #062f5e  1px solid;
  // border-right: #062f5e 1px solid;
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #062f5e;
    // border-bottom: #3498db 2px dotted;
    // border-left: aqua 2px dotted;
    // border-right: #062f5e 2px dotted;
    color: white;
    /* Change text color to white on hover */
    transform: translateY(-5px);
    box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.2);

    /* Change all child elements' colors */
    .metric-number,
    .metric-title,
    .metric-description {
      color: white;
    }
  }
}

.metric-number {
  font-size: 48px;
  font-weight: bold;
  color: #062f5e;
  margin-bottom: 10px;
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.metric-title {
  font-size: 18px;
  color: #214f81;
  margin-bottom: 15px;
  text-transform: uppercase;
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.metric-description {
  font-size: 1.125rem;
  color: black;
  font-family: "Montserrat", sans-serif;
  line-height: 2;
  transition: color 0.3s ease;
  /* Smooth color transition */
}

@media (max-width: 767px) {
  .metric-card {
    width: 100%;
  }
}

@media all and (min-width: 1100px) and (max-width: 1800px) {
  .metrics-container {
    margin-left: -20px;
  }
}

.card-shadow {
  // box-shadow: rgb(204, 219, 232) -5px  -6px 6px 0px inset,
  //   rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  box-shadow: rgba(204, 219, 232) -5px -5px 6px 0px inset,
    rgba(204, 219, 232, 0.5) 5px 5px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset,
    rgba(255, 255, 255, 0.5) 3px 3px 6px 1px inset;
  border-radius: 20px !important;
}

.update-btn {
  margin: 10px;
  // padding: 8px 25px;
  text-align: center;
  border-radius: 50px;
}

.text-underline {
  text-decoration: underline !important;
}

// Define the shimmer animation
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
.print-btn {
  font-size: 0.8rem;
}
.custom-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  .alert-danger {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    padding: 0.25rem;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;

    div {
      width: 100%;
    }

    .mb-3 {
      margin-bottom: 0.5rem !important;
    }
  }


  .card {
    width: 100%;
    border: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;

    legend {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .full-width {
    grid-column: span 2;
  }

  .suggestions-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 1000;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    left: 0;
    bottom: -55px;
  }

  .suggestions-list li {
    padding: 8px;
    cursor: pointer;
  }

  .suggestions-list li:hover {
    background-color: #f0f0f0;
  }

  .input-error {
    padding: 0.2rem;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

}

.full-width-3-col {
  grid-column: span 3;
}

// SCSS class for shimmer effect
.shimmer-placeholder {
  width: 100%; // Full width
  aspect-ratio: 200 / 37; // Maintain 200:37 aspect ratio
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.nav-tabs {
  .nav-item {
    .nav-link {
      cursor: pointer;
    }
  }
}

.btn-grad {
  // background-image: linear-gradient(to right,
  //     #002147 0%,
  //     #eb1c26 51%,
  //     #002147 100%);
  margin: 10px;
  padding: 8px 25px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 50px;
  border: none;
  justify-content: center;
  text-decoration: none;
  animation: btn-grad-animation 3s ease infinite alternate;
  background: linear-gradient(60deg, #e88d2a, #e3624c, #e33e72, #944e94, #46639e, #008b94, #009c86, #5ea773);
  background-size: 300% 300%;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}


@keyframes btn-grad-animation {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.error-text {
  font-size: 0.75rem;
  color: red;
}

.flipbook-card {
  border-radius: 1rem !important;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;

  .flipbook-container {
    overflow: auto;
  }

  .pagination-blue-book {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

.capsule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #016895;
  border-radius: 30px;
  padding: 20px;
  padding-left: 0px !important;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .column {
    &.first_section {
      display: flex;
      align-items: center;

      .blue_tag {
        background-color: #006998;
        padding: 10px 15px;
        color: white;
        position: relative;
        margin-right: 10px;
        display: inline-block;
        /* This makes sure it behaves like a label */

        /* The right triangular shape (ribbon style) */
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -20px;
          width: 0;
          height: 0;
          border-top: 22px solid #006998;
          border-right: 20px solid transparent;
          border-bottom: 22px solid #006998;
        }

        /* Left-side tab extension (the small dark blue rectangle) */
        &::before {
          content: "";
          position: absolute;
          top: -4px;
          left: -10px;
          width: 10px;
          height: 109%;
          background-color: #004a66;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 3px;
        }

        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          line-height: 30px;
        }
      }
    }

    &.two_section {
      ul {
        list-style-type: none;
        padding-left: 2rem;

        .list {
          margin: 5px 0;
          color: #016895;
          font-size: 14px;
          text-align: left !important;
          font-weight: 600;
        }
      }
    }

    &.three_section {
      img {
        width: 50px;
        height: auto;
        animation: zoom-in 0.3s ease;
      }
    }
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.color-primary {
  color: #016895;
}

.process-num {
  font-size: 16px;
  opacity: 90%;
  color: white;
  // background: white;
  width: auto;
  position: initial;
  display: block;
  height: auto;
  border-radius: 0;
  // margin-bottom: -12px;
  border-radius: 50%;
  padding-left: 7px;
  padding-right: 7px;
}

.book-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.page {
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.page-number {
  margin-top: 20px;
  font-size: 16px;
}

.login-card {
  .nav-tabs {
    border: none;
    margin-bottom: 1rem;
    justify-content: center;
    border-bottom: 1px solid #ebebeb;

    .nav-item {
      cursor: pointer;

      .nav-link {
        border: none;
        color: #929daf !important;
        font-weight: 500;
        padding: 0.5rem 1.5rem;
        max-height: 40px;
        min-height: 40px;
        background: transparent;
        border-radius: 100px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 0 0.5rem;
        font-weight: 600;

        @media screen and (max-width: 767px) {
          margin: 0.5rem 0.5rem;

        }

        svg {
          margin-right: 0.5rem;
        }

        &.active {
          border: none;
          position: relative;
          /* background: #062f5e !important; */
          color: #428BFF !important;
          /* font-weight: 600; */
          padding: 0.5rem 1.5rem;

          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            width: 70px;
            max-width: 100%;
            margin: 0 auto;
            height: 4px;
            background: #428BFF;
            border-radius: 1px;
          }
        }
      }

      &:hover {
        &::after {
          display: none;
        }
      }
    }
  }
}